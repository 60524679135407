// Import the 'Box' component from Material-UI
import { Box } from "@mui/material";

// Create a functional component named 'UserImage'
const UserImage = ({ image, size = "60px" }) => {
  return (
    <Box width={size} height={size}>
      <img
        style={{ objectFit: "cover", borderRadius: "50%" }}
        width={size}
        height={size}
        alt="user"
        src={`http://localhost:3002/assets/${image}`}
      />
    </Box>
  );
};

export default UserImage;