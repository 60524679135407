import { Box, Typography, useTheme } from "@mui/material";
import Friend from "components/Friend";
import WidgetWrapper from "components/WidgetWrapper";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFriends } from "state";

// Define the FriendListWidget component
const FriendListWidget = ({ userId }) => {
  const dispatch = useDispatch(); // Initialize the dispatch function from Redux
  const { palette } = useTheme(); // Get the theme's palette
  const token = useSelector((state) => state.token); // Get the token from Redux state
  const user = useSelector((state) => state.user); // Get the user data from Redux state
  const friends = user ? user.friends : null; // Get the friends array from the user data

   // Function to fetch and set the user's friends
  const getFriends = async () => {
    const response = await fetch(
      `http://localhost:3002/users/${userId}/friends`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();
    dispatch(setFriends({ friends: data }));
  };

  useEffect(() => {
    getFriends();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WidgetWrapper>
      <Typography
        color={palette.neutral.dark}
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        Friend List
      </Typography>
      <Box display="flex" flexDirection="column" gap="1.5rem">
        {/* Check if friends is not null and is an array before mapping */}
        {friends && Array.isArray(friends) && friends.map((friend) => (
          <Friend
            key={friend._id}
            friendId={friend._id}
            name={`${friend.firstName} ${friend.lastName}`}
            subtitle={friend.occupation}
            userPicturePath={friend.picturePath}
          />
        ))}
      </Box>
    </WidgetWrapper>
  );
};

export default FriendListWidget;

